var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer", attrs: { align: "right" } }, [
    _c(
      "div",
      {
        staticClass: "shadow-24",
        staticStyle: { "padding-left": "10px", "padding-right": "10px" },
        attrs: { align: "center" },
      },
      [
        _c("table", [
          _c("tbody", [
            _c("tr", [
              _c("td", { attrs: { align: "left" } }, [
                _vm._v(_vm._s(_vm.unita_operativa)),
              ]),
              _c(
                "td",
                { attrs: { rowspan: "2" } },
                [
                  _c(
                    "q-avatar",
                    {
                      attrs: {
                        color: "white",
                        "text-color": "white",
                        size: "64px",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.avatar, width: "52px" } })]
                  ),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { attrs: { align: "left" } }, [
                _vm._v(_vm._s(_vm.operatore)),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }